//

@import "~bootstrap";
@import "~datatables.net-bs4";
@import "~datatables.net-buttons-bs4";
@import "~font-awesome";
@import "~select2";
@import "~select2-bootstrap-css";
@import "bootstrap-lanslide.scss";

html {
    background: url("/images/patternoverlay2.png") repeat,
        url("/images/background2.jpg") no-repeat center center fixed;
    -webkit-background-size: auto, cover;
    -moz-background-size: auto, cover;
    -o-background-size: auto, cover;
    background-size: auto, cover;
}

.container {
    min-width: 1000px;
}

body {
    color: #ffffff;
    width: 100%;
}

.table-lanslide>tbody>tr>td {
    vertical-align: middle;
    background-color: rgba(0, 0, 0, 0.5);
}

.btn-group-xs>.btn,
.btn-xs {
    padding: .25rem .4rem;
    font-size: .875rem;
    line-height: .5;
    border-radius: .2rem;
}